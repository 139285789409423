import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import SplashTitle from "../components/SplashTitle";
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import ContactUsBar from "../components/ContactUsBar";

export const pageQuery = graphql`
  {
    splashImage: file(relativePath: { eq: "highrise-and-crane.jpg" }) {
      ...SplashImage
    }
    site {
      siteMetadata {
        defaultLanguage
      }
    }
  }
`;

export default function SatelliteImageryPage({ data }) {
  const intl = useIntl();

  return (
    <Layout
      intl={intl}
      title={intl.formatMessage({ id: "satellite-imagery.title", defaultMessage: "Enabling Personalized Access to Current Satellite Images" })}
    >
      <Seo
        intl={intl}
        title={intl.formatMessage({ id: "satellite-imagery.title", defaultMessage: "Enabling Personalized Access to Current Satellite Images" })}
      />
      <SplashTitle
        image={getImage(data.splashImage)}
        subheadline={intl.formatMessage({ id: "satellite-imagery.subheadline", defaultMessage: "Outdated Satellite Imagery" })}
      >
        <FormattedMessage id="satellite-imagery.headline" defaultMessage="Enabling Personalized Access to Current Satellite Images" />
      </SplashTitle>
      <main>
        <div className="max-w-2xl lg:max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 px-4 lg:px-8">
          <div className="grid grid-cols-1 items-center gap-y-4 md:gap-y-10 gap-x-16 lg:grid-cols-2">
            <h2 className="text-4xl md:text-5xl leading-tight md:leading-snug font-extrabold tracking-tight text-gray-900">
              {intl.formatMessage(
                {
                  id: "satellite-imagery.main-section-headline",
                  defaultMessage: "Multi-temporal high resolution <mark>satellite image viewer</mark>",
                },
                { mark: (text) => <mark>{text}</mark> }
              )}
            </h2>
            <p className="mt-4 text-gray-500 prose-md md:prose-lg">
              <FormattedMessage
                id="satellite-imagery.description"
                defaultMessage="Online satellite image maps are great! They have changed the way how we look at our planet. However, they usually lag behind in time and are the therefore not very valuable for real estate applications. To solve this problem, ubicube develops a tool that allows its users to view archived and current satellite imagery in their areas of interest."
              />
            </p>
          </div>
          <div className="w-full my-24">
            <h2 className="text-2xl mb-8 md:text-4xl md:mb-12 font-extrabold tracking-tight text-gray-900">
              <FormattedMessage id="satellite-imagery.how-does-it-work" defaultMessage="How does it work?" />
            </h2>
            <div className="mt-4 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/satellite.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="satellite-imagery.section-1-headline-1" defaultMessage="Satellite Imagery" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="satellite-imagery.section-1-text-1"
                      defaultMessage="Through ubicube you can access archives of numerous high resolution (<1m) satellite image providers."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/personalization.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="satellite-imagery.section-1-headline-2" defaultMessage="Personalization" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="satellite-imagery.section-1-text-2"
                      defaultMessage="Based on the users’ specifications (location and time) data archives are searched for matching data. It is also possible to define update frequencies (e.g. one image every day for a month)."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/screen.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="satellite-imagery.section-1-headline-3" defaultMessage="Satellite Image Viewer" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="satellite-imagery.section-1-text-3"
                      defaultMessage="The satellite images can be viewed in our intuitive online viewer."
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full my-24"></div>
          <div>
            <h2 className="text-2xl mb-8 md:text-4xl md:mb-12 font-extrabold tracking-tight text-gray-900">
              <FormattedMessage
                id="satellite-imagery.headline-benefits"
                defaultMessage="Your <mark>benefits</mark>"
                values={{ mark: (text) => <mark>{text}</mark> }}
              />
            </h2>

            <div className="mt-4 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/tachymetre.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="satellite-imagery.section-2-headline-1" defaultMessage="Full Temporal Flexibility" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="satellite-imagery.section-2-text-1"
                      defaultMessage="No more timing issues when it comes to watching properties from a birds’ eye view. Access archived and current satellite imagery."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/earth_longlat.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="satellite-imagery.section-2-headline-2" defaultMessage="Full Geographical Flexibility" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="satellite-imagery.section-2-text-2"
                      defaultMessage="Get satellite imagery for any location. Save money through specification of personal areas of interest."
                    />{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white max-w-2xl lg:max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8 xl:mb-32">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900">
                <FormattedMessage
                  id="satellite-imagery.use-cases-headline"
                  defaultMessage="<mark>Use-cases</mark>"
                  values={{ mark: (text) => <mark>{text}</mark> }}
                />
              </h2>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                <div>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    <FormattedMessage
                      id="satellite-imagery.use-case-1"
                      defaultMessage="Optimized Planning of Field Trips for Municipal Building Inspections"
                    />
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    <FormattedMessage
                      id="satellite-imagery.use-case-1-text"
                      defaultMessage="With ubicubes’ satellite image viewing tool, municipal building inspections can minimize field trips to construction sites as they can monitor the progress from their desks."
                    />
                  </dd>
                </div>
                <div>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    <FormattedMessage
                      id="satellite-imagery.use-case-2"
                      defaultMessage="Improvement of Property Descriptions for Real Estate Agents"
                    />
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    <FormattedMessage
                      id="satellite-imagery.use-case-2-text"
                      defaultMessage="With our technology we can help real estate agents to prepare property exposés that impress prospective buyers."
                    />
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="max-w-screen-2xl mx-auto my-20">
          <ContactUsBar intl={intl} />
        </div>
      </main>
    </Layout>
  );
}
